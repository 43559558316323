<template>
  <client-only>
    <template v-if="app.isMobile || app.isTablet">
      <bottom-drawer v-model:visible="isShown" :title="props.title" :mask-closable="maskClosable" class="viewer-drawer" :drawer-height="drawerHeight">
        <template #trigger="{ open }">
          <slot name="trigger" :open="open">
            <nd-button type="text" class="active" @click="handleTriggerClick(open)">{{ $t('all_check') }}</nd-button>
          </slot>
        </template>
        <template #default="{ close }">
          <div class="main-content px-5" :class="drawerMainContentClass">
            <slot name="default" :close="() => handleModalCloseClick(close)"></slot>
          </div>
        </template>
        <template #footer>
          <slot name="footer" :close="handleModalCloseClick"></slot>
        </template>
      </bottom-drawer>
    </template>
    <template v-else>
      <slot name="trigger" :open="handleTriggerClick">
        <nd-button type="text" class="active" @click="handleTriggerClick">{{ $t('all_check') }}</nd-button>
      </slot>
      <n-modal v-model:show="isShown" :z-index="zIndex.modal" :mask-closable="maskClosable">
        <div class="viewer-modal rounded bg-white" :class="modalClass">
          <div class="modal-header flex items-center justify-between gap-2 py-4 px-5">
            <span class="title text-lg">{{ props.title }}</span>
            <svg-icon name="cancel" class="cursor-pointer w-10 h-10 p-2 -mt-2 -mr-2" @click="handleModalCloseClick" />
          </div>
          <div class="main-content pt-4 px-5" :class="modalMainContentClass">
            <slot name="default" :close="handleModalCloseClick"></slot>
          </div>
          <slot name="footer" :close="handleModalCloseClick"></slot>
        </div>
        <template #footer>
          <slot name="footer" :close="handleModalCloseClick"></slot>
        </template>
      </n-modal>
    </template>
  </client-only>
</template>

<script setup lang="ts">
import { NModal } from 'naive-ui'
import { useAppStore } from '@/store/app'

import BottomDrawer from '@/components/common/bottom-drawer.vue'

import { zIndex } from '@/theme.config'

type Props = {
  title?: string
  modalClass?: string
  modalMainContentClass?: string
  drawerHeight?: string | number
  drawerMainContentClass?: string
  maskClosable?: boolean
}

const app = useAppStore()

const props = withDefaults(defineProps<Props>(), {
  title: '',
  modalClass: '',
  modalMainContentClass: '',
  drawerHeight: '',
  drawerMainContentClass: '',
  maskClosable: true,
})

const emit = defineEmits<{
  (event: 'open'): void
  (event: 'close'): void
}>()

const isShown = ref(false)

watch(isShown, newIsShown => {
  if (newIsShown) {
    emit('open')
  } else {
    emit('close')
  }
})

const handleTriggerClick = (openFn?: Function) => {
  if (typeof openFn === 'function') openFn()
  isShown.value = true
}

const handleModalCloseClick = (closeFn?: Function) => {
  if (typeof closeFn === 'function') closeFn()
  isShown.value = false
}
</script>

<style lang="scss" scoped></style>
